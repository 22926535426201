{
  "name": "Soulmate",
  "browserslist": [
    "last 1 Chrome versions",
    "last 1 electron version"
  ],
  "version": "2.2.59",
  "main": "src/electron.js",
  "electron": "electron-dist/index.html",
  "browser": "dist/index.html",
  "entries": {
    "electron": "index.html"
  },
  "engines": {
    "node": "15.x"
  },
  "targets": {
    "main": false,
    "electron": {
      "distDir": "electron-dist",
      "scopeHoist": false,
      "publicUrl": ".",
      "context": "browser"
    },
    "browser": {
      "distDir": "dist",
      "scopeHoist": false,
      "publicUrl": "/",
      "context": "browser"
    }
  },
  "license": "MIT",
  "dependencies": {
    "@react-icons/all-files": "^4.1.0",
    "@sentry/electron": "^2.0.1",
    "@serialport/bindings": "9.0.2",
    "babel-plugin-import": "^1.13.3",
    "bonjour": "^3.5.0",
    "date-fns": "^2.21.1",
    "electron-auth0-login": "^1.3.1",
    "electron-is-dev": "^1.2.0",
    "electron-log": "^4.3.0",
    "electron-root-path": "^1.0.16",
    "electron-updater": "^4.3.8",
    "express": "^4.17.1",
    "prerender-node": "^3.2.5",
    "react-ga": "^3.3.0",
    "react-use": "^17.1.1",
    "serialport": "9.0.2",
    "terser": "^5.14.1"
  },
  "optionalDependencies": {
    "keytar": "^7.6.0"
  },
  "devDependencies": {
    "@auth0/auth0-spa-js": "^1.13.6",
    "@babel/core": "^7.12.10",
    "@babel/helper-builder-react-jsx": "^7.10.4",
    "@babel/plugin-proposal-optional-chaining": "^7.10.4",
    "@babel/plugin-transform-runtime": "^7.10.1",
    "@babel/preset-env": "^7.10.2",
    "@babel/preset-react": "^7.10.1",
    "@babel/runtime": "^7.10.2",
    "@elliottkember/leduino": "^1.0.9",
    "@fullhuman/postcss-purgecss": "^2.3.0",
    "@hot-loader/react-dom": "^16.13.0",
    "@parcel/packager-raw-url": "2.0.0-beta.2",
    "@parcel/transformer-image": "2.0.0-beta.2",
    "@parcel/transformer-svg-react": "^2.0.0-nightly.1739",
    "@parcel/transformer-svgo": "^2.0.0-nightly.1739",
    "@parcel/transformer-webmanifest": "2.0.0-beta.2",
    "@sentry/cli": "^1.64.0",
    "@sentry/integrations": "^5.24.2",
    "@sentry/react": "^6.2.3",
    "@sentry/tracing": "^5.24.2",
    "@svgr/parcel-plugin-svgr": "^5.5.0",
    "@szhsin/react-menu": "^1.5.0",
    "@tailwindcss/forms": "^0.2.1",
    "@tailwindcss/ui": "^0.5.0",
    "@twuni/emojify": "^1.0.2",
    "@use-it/event-listener": "^0.1.5",
    "@use-it/interval": "^0.1.3",
    "@wokwi/elements": "^0.27.2",
    "@wokwi/gcc-output-parser": "^0.2.1",
    "ansicolor": "^1.1.93",
    "autoprefixer": "^9.8.6",
    "babel-core": "^7.0.0-bridge.0",
    "babel-eslint": "^10.1.0",
    "babel-plugin-auto-import": "^1.0.5",
    "babel-plugin-root-import": "^6.5.0",
    "babel-preset-env": "^1.7.0",
    "classnames": "^2.2.6",
    "concurrently": "^5.2.0",
    "containers": "^0.0.1",
    "cross-env": "^7.0.2",
    "electron": "^11.3.0",
    "electron-builder": "^22.11.7",
    "electron-builder-notarize": "^1.2.0",
    "electron-notarize": "^1.0.0",
    "electron-rebuild": "^2.3.4",
    "emittery": "^0.7.1",
    "eslint": "^7.15.0",
    "eslint-plugin-auto-import": "^0.1.0",
    "eslint-plugin-babel": "^5.3.1",
    "eslint-plugin-react": "^7.21.5",
    "eslint-plugin-react-hooks": "^4.2.0",
    "eslint-plugin-simple-import-sort": "^7.0.0",
    "eslint-plugin-tailwind": "^0.2.0",
    "history": "^4.10.1",
    "husky": "^5.1.3",
    "jest": "^26.4.2",
    "js-beautify": "^1.13.11",
    "js-cookie": "^2.2.1",
    "jwt-decode": "^2.2.0",
    "lodash": "^4.17.15",
    "lost": "^8.3.1",
    "mocha": "^8.3.2",
    "moment": "^2.27.0",
    "node-abi": "^2.19.3",
    "node-gyp": "7.1.2",
    "normalize-url": "4",
    "npm-version": "^1.1.0",
    "parcel": "^2.0.0-beta.2",
    "postcss": "^8.2.10",
    "postcss-color-function": "^4.1.0",
    "postcss-custom-media": "^8.0.0",
    "postcss-custom-properties": "^11.0.0",
    "postcss-import": "^14.0.1",
    "postcss-modules": "^3.2.0",
    "postcss-utilities": "^0.8.4",
    "posthtml-include": "^1.7.0",
    "prettier": "2.2.1",
    "prop-types": "^15.7.2",
    "react": "16.11.0",
    "react-helmet": "^6.1.0",
    "react-hot-loader": "^4.12.21",
    "react-icons": "^4.1.0",
    "react-monaco-editor": "0.42.0",
    "react-resizable": "^1.11.0",
    "react-router-dom": "^5.2.0",
    "react-router-last-location": "^2.0.1",
    "react-sortable-hoc": "^1.11.0",
    "react-transition-group": "^4.4.1",
    "request": "^2.88.2",
    "request-promise-native": "^1.0.8",
    "semantic-ui-css": "^2.4.1",
    "spectron": "13",
    "style-it": "^2.1.4",
    "swr": "^0.5.5",
    "tailwindcss": "^2.0.1",
    "typescript": "^4.0.2",
    "use-light-switch": "^1.0.1",
    "wait-on": "^5.0.0"
  },
  "alias": {
    "@auth0/auth0-spa-js": "./node_modules/@auth0/auth0-spa-js/dist/lib/auth0-spa-js.cjs.js",
    "react-dom": "@hot-loader/react-dom"
  },
  "cacheDirectories": [
    ".parcel-cache",
    "node_modules"
  ],
  "scripts": {
    "// web stuff": "online",
    "react-start": "parcel -p 3000 index.html --target browser",
    "build-web": "parcel build index.html --target browser",
    "start": "concurrently \"cross-env BROWSER=none yarn react-start\" \"wait-on http://localhost:3000 && electron . \"",
    "web": "NODE_ENV=development parcel index.html --open -p 3000",
    "heroku-postbuild": "yarn build-web",
    "// electron stuff": "builds",
    "build-electron": "parcel build index.html --target electron",
    "build-app": "yarn build-electron && electron-builder -m",
    "rebuild-electron": "yarn node-gyp clean && yarn electron-rebuild",
    "bump": "yarn version --patch && git push && git push --tags",
    "// setup and cleanup": "write and delete stuff",
    "clean": "rm -rf ./build ./.parcel-cache ./.cache ./dist ./electron-dist ./app-builds",
    "install-app-deps": "./postinstall.sh",
    "install-arm": "npm_config_arch=$(uname -m) yarn",
    "prepare": "husky install",
    "postinstall": "cp bundle-url.js node_modules/@parcel/runtime-js/lib/bundle-url.js",
    "// testing": "run tests",
    "test": "mocha"
  },
  "build": {
    "afterSign": "electron-builder-notarize",
    "afterPack": "./afterpack",
    "win": {
      "publish": {
        "provider": "s3",
        "bucket": "soulmate-ide-updates"
      }
    },
    "protocols": {
      "name": "soulmate",
      "schemes": [
        "soulmate"
      ]
    },
    "mac": {
      "minimumSystemVersion": "10.12.0",
      "target": [
        {
          "target": "default",
          "arch": "universal"
        }
      ],
      "publish": {
        "provider": "s3",
        "bucket": "soulmate-ide-updates"
      },
      "entitlements": "entitlements.plist",
      "entitlementsInherit": "entitlements.plist",
      "gatekeeperAssess": false,
      "hardenedRuntime": true
    },
    "dmg": {
      "sign": false
    },
    "directories": {
      "buildResources": "assets",
      "output": "app-builds"
    },
    "appId": "com.elliottkember.soulmate",
    "files": [
      "src/icon.icns",
      "src/icon.png",
      "src/electron.js",
      "src/preload.js",
      "src/auth.js",
      "electron-dist",
      "package.json"
    ],
    "extraFiles": [
      "builder"
    ]
  },
  "resolutions": {
    "@serialport/bindings": "9.0.2",
    "@serialport/stream": "9.0.2",
    "babel-core": "7.0.0-bridge.0",
    "sharp": "0.30.4",
    "monaco-editor": "0.23.0"
  }
}
