export const dataTypes = [
  "CFastLED",
  "CHSV",
  "CRGB",
  "CRGBArray",
  "LEDS",
  "FastLED",
  "FastPin",
  "FastSPI",
  "FastSPI_LED2",

  "CRGBPalette16",
  "CRGBPalette256",
  "CHSVPalette16",
  "CHSVPalette256",
  "CHSVPalette16",
  "CHSVPalette256",
  "CRGBPalette16",
  "CRGBPalette256",
];

export const fastLEDMethods = [
  "addLeds",
  "setBrightness",
  "getBrightness",
  "show",
  "clear",
  "clearData",
  "showColor",
  "setTemperature",
  "setCorrection",
  "setDither",
  "setMaxPowerInMilliWatts",
  "setMaxPowerInVoltsAndMilliamps",
  "setMaxRefreshRate",
  "countFPS",
  "getFPS",

  // lib8tion
  "lerp8by8",
  "lerp16by16",
  "lerp16by8",
  "lerp15by8",
  "lerp15by16",
  "map8",
  "ease8InOutQuad",
  "ease8InOutQuad",
  "ease16InOutQuad",
  "ease8InOutCubic",
  "ease8InOutApprox",
  "ease8InOutApprox",
  "triwave8",
  "quadwave8",
  "cubicwave8",
  "squarewave8",
  "beat88",
  "beat16",
  "beat8",
  "beatsin88",
  // "beatsin16",
  // "beatsin8",
  "seconds16",
  "minutes16",
  "hours8",
  "div1024_32_16",
  "bseconds16",
];

export const methods = [
  ...fastLEDMethods,

  // Noise methods
  "inoise16_raw",
  "inoise8_raw",
  "inoise16",
  "inoise8",
  "fill_2dnoise16",
  "fill_2dnoise8",
  "fill_noise16",
  "fill_noise8",
  "fill_raw_2dnoise16",
  "fill_raw_2dnoise16into8",
  "fill_raw_2dnoise8",
  "fill_raw_noise16into8",
  "fill_raw_noise8",

  // Lib8tion methods
  "qadd8",
  "qadd7",
  "qsub8",
  "add8",
  "sub8",
  "scale8",
  "scale8_video",
  "cleanup_R1",
  "nscale8x3",
  "nscale8x3_video",
  "nscale8x2",
  "nscale8x2_video",
  "scale16by8",
  "scale16by8",
  "scale16",
  "mul8",
  "qmul8",
  "abs8",
  "dim8_raw",
  "dim8_video",
  "dim8_lin",
  "brighten8_raw",
  "brighten8_video",
  "brighten8_lin",
  "random8",
  "random16",
  "random8",
  "random8",
  "random16",
  "random16",
  "random16_set_seed",
  "random16_get_seed",
  "random16_add_entropy",
  "sin16_avr",
  "sin16",
  "cos16",
  "sin8",
  "cos8",
  "lerp8by8",
  "lerp16by16",
  "lerp16by8",
  "lerp15by8",
  "lerp15by16",
  "map8",
  "ease8InOutQuad",
  "ease8InOutCubic",
  "ease8InOutApprox",
  "ease8InOutApprox",
  "triwave8",
  "quadwave8",
  "cubicwave8",
  "sqrt16",
  "blend8",

  // Color util methods
  "blend",
  "nblend",
  "ColorFromPalette",
  "HeatColor",
  "UpscalePalette",
  "blend",
  "fadeLightBy",
  "fadeToBlackBy",
  "fade_raw",
  "fade_video",
  "fill_gradient",
  "fill_gradient_RGB",
  "fill_palette",
  "fill_rainbow",
  "fill_solid",
  "map_data_into_colors_through_palette",
  "nblend",
  "nscale8",
  "nscale8_video",

  // HSV methods
  "hsv2grb_rainbow",
  "hsv2rgb_spectrum",
  "hsv2rgb_raw",
  "fill_solid",
  "fill_rainbow",

  // new blur functions
  "blur1d",
  "blur2d",
  "blurRows",
  "blurColumns",

  // Colors
  "CRGB::AliceBlue",
  "CRGB::Amethyst",
  "CRGB::AntiqueWhite",
  "CRGB::Aqua",
  "CRGB::Aquamarine",
  "CRGB::Azure",
  "CRGB::Beige",
  "CRGB::Bisque",
  "CRGB::Black",
  "CRGB::BlanchedAlmond",
  "CRGB::Blue",
  "CRGB::BlueViolet",
  "CRGB::Brown",
  "CRGB::BurlyWood",
  "CRGB::CadetBlue",
  "CRGB::Chartreuse",
  "CRGB::Chocolate",
  "CRGB::Coral",
  "CRGB::CornflowerBlue",
  "CRGB::Cornsilk",
  "CRGB::Crimson",
  "CRGB::Cyan",
  "CRGB::DarkBlue",
  "CRGB::DarkCyan",
  "CRGB::DarkGoldenrod",
  "CRGB::DarkGray",
  "CRGB::DarkGreen",
  "CRGB::DarkKhaki",
  "CRGB::DarkMagenta",
  "CRGB::DarkOliveGreen",
  "CRGB::DarkOrange",
  "CRGB::DarkOrchid",
  "CRGB::DarkRed",
  "CRGB::DarkSalmon",
  "CRGB::DarkSeaGreen",
  "CRGB::DarkSlateBlue",
  "CRGB::DarkSlateGray",
  "CRGB::DarkTurquoise",
  "CRGB::DarkViolet",
  "CRGB::DeepPink",
  "CRGB::DeepSkyBlue",
  "CRGB::DimGray",
  "CRGB::DodgerBlue",
  "CRGB::FireBrick",
  "CRGB::FloralWhite",
  "CRGB::ForestGreen",
  "CRGB::Fuchsia",
  "CRGB::Gainsboro",
  "CRGB::GhostWhite",
  "CRGB::Gold",
  "CRGB::Goldenrod",
  "CRGB::Gray",
  "CRGB::Green",
  "CRGB::GreenYellow",
  "CRGB::Honeydew",
  "CRGB::HotPink",
  "CRGB::IndianRed",
  "CRGB::Indigo",
  "CRGB::Ivory",
  "CRGB::Khaki",
  "CRGB::Lavender",
  "CRGB::LavenderBlush",
  "CRGB::LawnGreen",
  "CRGB::LemonChiffon",
  "CRGB::LightBlue",
  "CRGB::LightCoral",
  "CRGB::LightCyan",
  "CRGB::LightGoldenrodYellow",
  "CRGB::LightGreen",
  "CRGB::LightGrey",
  "CRGB::LightPink",
  "CRGB::LightSalmon",
  "CRGB::LightSeaGreen",
  "CRGB::LightSkyBlue",
  "CRGB::LightSlateGray",
  "CRGB::LightSteelBlue",
  "CRGB::LightYellow",
  "CRGB::Lime",
  "CRGB::LimeGreen",
  "CRGB::Linen",
  "CRGB::Magenta",
  "CRGB::Maroon",
  "CRGB::MediumAquamarine",
  "CRGB::MediumBlue",
  "CRGB::MediumOrchid",
  "CRGB::MediumPurple",
  "CRGB::MediumSeaGreen",
  "CRGB::MediumSlateBlue",
  "CRGB::MediumSpringGreen",
  "CRGB::MediumTurquoise",
  "CRGB::MediumVioletRed",
  "CRGB::MidnightBlue",
  "CRGB::MintCream",
  "CRGB::MistyRose",
  "CRGB::Moccasin",
  "CRGB::NavajoWhite",
  "CRGB::Navy",
  "CRGB::OldLace",
  "CRGB::Olive",
  "CRGB::OliveDrab",
  "CRGB::Orange",
  "CRGB::OrangeRed",
  "CRGB::Orchid",
  "CRGB::PaleGoldenrod",
  "CRGB::PaleGreen",
  "CRGB::PaleTurquoise",
  "CRGB::PaleVioletRed",
  "CRGB::PapayaWhip",
  "CRGB::PeachPuff",
  "CRGB::Peru",
  "CRGB::Pink",
  "CRGB::Plaid",
  "CRGB::Plum",
  "CRGB::PowderBlue",
  "CRGB::Purple",
  "CRGB::Red",
  "CRGB::RosyBrown",
  "CRGB::RoyalBlue",
  "CRGB::SaddleBrown",
  "CRGB::Salmon",
  "CRGB::SandyBrown",
  "CRGB::SeaGreen",
  "CRGB::Seashell",
  "CRGB::Sienna",
  "CRGB::Silver",
  "CRGB::SkyBlue",
  "CRGB::SlateBlue",
  "CRGB::SlateGray",
  "CRGB::Snow",
  "CRGB::SpringGreen",
  "CRGB::SteelBlue",
  "CRGB::Tan",
  "CRGB::Teal",
  "CRGB::Thistle",
  "CRGB::Tomato",
  "CRGB::Turquoise",
  "CRGB::Violet",
  "CRGB::Wheat",
  "CRGB::White",
  "CRGB::WhiteSmoke",
  "CRGB::Yellow",
  "CRGB::YellowGreen",
];

export const constants = [
  // Chipsets
  "APA102",
  "APA104",
  "APA106",
  "DMXSERIAL",
  "DMXSIMPLE",
  "DOTSTAR",
  "GE8822",
  "GS1903",
  "GW6205",
  "GW6205B",
  "GW6205_400",
  "LPD1886",
  "LPD1886_8BIT",
  "LPD6803",
  "LPD8806",
  "NEOPIXEL",
  "OCTOWS2811",
  "OCTOWS2811_400",
  "OCTOWS2813",
  "P9813",
  "PIXIE",
  "PL9823",
  "SK6812",
  "SK6822",
  "SK9822",
  "SM16703",
  "SM16716",
  "SMART_MATRIX",
  "TM1803",
  "TM1804",
  "TM1809",
  "TM1812",
  "TM1829",
  "UCS1903",
  "UCS1903B",
  "UCS1904",
  "UCS2903",
  "WS2801",
  "WS2803",
  "WS2811",
  "WS2811_400",
  "WS2812",
  "WS2812B",
  "WS2812SERIAL",
  "WS2813",
  "WS2852",

  // RGB orderings
  "RGB",
  "RBG",
  "GRB",
  "GBR",
  "BRG",
  "BGR",

  // Hue literals
  "HUE_RED",
  "HUE_ORANGE",
  "HUE_YELLOW",
  "HUE_GREEN",
  "HUE_AQUA",
  "HUE_BLUE",
  "HUE_PURPLE",
  "HUE_PINK",

  // Color correction values
  "TypicalSMD5050",
  "TypicalLEDStrip",
  "Typical8mmPixel",
  "TypicalPixelString",
  "UncorrectedColor",
  "Candle",
  "Tungsten40W",
  "Tungsten100W",
  "Halogen",
  "CarbonArc",
  "HighNoonSun",
  "DirectSunlight",
  "OvercastSky",
  "ClearBlueSky",
  "WarmFluorescent",
  "StandardFluorescent",
  "CoolWhiteFluorescent",
  "FullSpectrumFluorescent",
  "GrowLightFluorescent",
  "BlackLightFluorescent",
  "MercuryVapor",
  "SodiumVapor",
  "MetalHalide",
  "HighPressureSodium",
  "UncorrectedTemperature",

  // Color util literals
  "FORWARD_HUES",
  "BACKWARD_HUES",
  "SHORTEST_HUES",
  "LONGEST_HUES",
  "LINEARBLEND",
  "NOBLEND",
];

import * as monaco from "monaco-editor";
function createDependencyProposals(range) {
  // Some of these come from https://github.com/FastLED/FastLED/blob/d5ddf40d3f3731adb36c122abba29cbf80654be3/src/colorutils.h
  const insertTextRules =
    monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet;
  const kind = monaco.languages.CompletionItemKind.Function;
  return [
    {
      label: "XY(x, y) - takes X and Y, and returns index",
      kind,
      insertText: "XY(${1:uint8_t x}, ${2:uint8_t x})",
      insertTextRules,
      range,
    },
    {
      label: "CRGB(red, green, blue)",
      kind,
      insertText: "CRGB(${1:red}, ${2:green}, ${3:blue})",
      insertTextRules,
      range,
    },
    {
      label: "CHSV(hue, saturation, brightness)",
      kind,
      insertText: "CHSV(${1:hue}, ${2:saturation}, ${3:brightness})",
      insertTextRules,
      range,
    },
    {
      label: "fadeToBlackBy(CRGB* leds, int NUM_LEDS, int fade);",
      description:
        "reduce the brightness of an array of pixels all at once.  These functions will eventually fade all the way to black.",
      kind,
      insertText: "fadeToBlackBy(${1:leds}, ${2:NUM_LEDS}, ${3:64});",
      insertTextRules,
      range,
    },
    {
      label: "fadeLightBy(CRGB* leds, int NUM_LEDS, int fade);",
      description:
        "Reduce the brightness of an array of pixels all at once.  Guaranteed to never fade all the way to black.",
      kind,
      insertText: "fadeToBlackBy(${1:leds}, ${2:NUM_LEDS}, ${3:64});",
      insertTextRules,
      range,
    },
    {
      label: "leds[index]",
      description: "The array of LEDs",
      kind,
      insertText: "leds[${1:0}]",
      insertTextRules,
      range,
    },
    {
      label: "NUM_LEDS",
      description: "The number of LEDs in your Soulmate",
      kind,
      insertText: "NUM_LEDS",
      insertTextRules,
      range,
    },
    {
      label: "COLS",
      description: "The number of columns in your LED matrix",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "COLS",
      insertTextRules,
      range,
    },
    {
      label: "ROWS",
      description: "The number of ropws in your LED matrix",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "COLS",
      insertTextRules,
      range,
    },
    {
      label: "for-loop: x/y",
      description: "Loop over all your LEDs",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText:
        "for (int x = 0; x < COLS; x++) {\n\
  for (int y = 0; y < ROWS; y++) {\n\
    int index = XY(x, y);\n\
    leds[index] = ${1:CRGB(255, 0, 0)};\n\
  }\n\
}",
      insertTextRules,
      range,
    },
    {
      label: "for-loop: index",
      description: "Loop over all your LEDs",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText:
        "for (int i = 0; i < N_LEDS; i++) {\n\
  leds[i] = ${1:CRGB(255, 0, 0)};\n\
}",
      insertTextRules,
      range,
    },
    {
      label: "beatsin8 - 8-bit sine-wave function",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "beatsin8(${1:int bpm}, ${2:int minimum}, ${3:int maximum});",
      insertTextRules,
      range,
    },
    {
      label: "beatsin16 - 16-bit sine-wave function",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText:
        "beatsin16(${1:int bpm}, ${2:uint16_t minimum}, ${3:uint16_t maximum});",
      insertTextRules,
      range,
    },
    {
      label: "random16() - 16-bit random function",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "random16(${1:uint16_t maximum});",
      insertTextRules,
      range,
    },
    {
      label: "blur1d(CRGB* leds, uint16_t numLeds, fract8 blur_amount);",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "blur1d(leds, ${1:NUM_LEDS}, ${2:64});",
      insertTextRules,
      range,
    },
    {
      label:
        "blur2d(CRGB* leds, uint8_t width, uint8_t height, fract8 blur_amount);",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "blur2d(leds, ${1:LED_COLS}, ${2:LED_ROWS}, ${3:64});",
      insertTextRules,
      range,
    },
    {
      label:
        "blurRows(CRGB* leds, uint8_t width, uint8_t height, fract8 blur_amount);",
      description: "perform a blur1d on every row of a rectangular matrix",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "blurRows(leds, ${1:LED_COLS}, ${2:LED_ROWS}, ${3:64});",
      insertTextRules,
      range,
    },
    {
      label:
        "blurColumns(CRGB* leds, uint8_t width, uint8_t height, fract8 blur_amount);",
      description: "perform a blur1d on each column of a rectangular matrix",
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: "blurColumns(leds, ${1:LED_COLS}, ${2:LED_ROWS}, ${3:64});",
      insertTextRules,
      range,
    },
    ...[...methods, ...constants].map((method) => ({
      label: method,
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: method,
      insertTextRules,
      range,
    })),
  ];
}

monaco.languages.registerCompletionItemProvider("soulmate", {
  provideCompletionItems: function (model, position) {
    var word = model.getWordUntilPosition(position);
    var range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    };
    return {
      suggestions: createDependencyProposals(range),
    };
  },
});
